<template>
	<div>
		<div class="box">
			<CustomTable
				id_table="contract_config"
				ref="contract_config"
				:items="filtredContractConfig"
				:busy="table_busy"
				primaryKey="contractconfig_id"
				:hrefsRoutes="config_table_hrefs"
				:hide_if_empty="true"
			/>
		</div>

		<b-modal ref="modalAddContractConfig" hide-footer>
			<template v-slot:modal-title>
				{{ $t("monte.ajouter_contract_config") }}
			</template>
			<ErrorAlert v-if="error_message !== ''" :messageI18n="error_message" />
			<div class="form-group">
				<label for="">{{ $t('gynecologie.saison') }} *</label>

				<e-select
					v-model="season"
					track-by="name"
					label="name"
					:placeholder="labelTitleSeason"
					:selectedLabel="selectedLabelSeason"
					:options="season_options"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
					:class="{ 'is-invalid': errors && errors.indexOf('season') > -1 }"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="col-8 m-auto" v-if="ready">
				<b-button block pill variant="primary" @click.prevent="saveAddContractConfig"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.enregistrer") }}</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import GynecologieMixin from "@/mixins/Gynecologie.js"
	import Model from "@/mixins/Model.js"

	export default {
		name: "ContractConfigStallionList",
		mixins: [TableAction, Navigation, ContractMixin, Model, GynecologieMixin],
		props: ['horse'],
		data () {
			return {
				ready: false,
				table_busy: false,
				season: null,
				error_message: '',
				labelTitleSeason: this.getTrad("monte.rechercher_saison"),
				selectedLabelSeason: this.getTrad("global.selected_label"),
				contractsConfig: [],
				season_options: [],
				errors: [],
				processing: false,
				config_table_hrefs: {
					'season.season_label': {
						routeUniqueName: 'ContractConfigForm',
						routeName: 'ContractConfigForm',
						params: {
							contractconfig_id: 'contractconfig_id'
						}
					}
            	},
				events_tab: {
				    'TableAction::goToAddContractConfig': this.openModalAddContractConfig,
				    'TableAction::goToEditContractConfig': (params) => {
				    	this.$router.push({ name: 'ContractConfigForm', params : params })
				    },
					'TableAction::goToDeleteContractConfig': (params) => {
				    	this.deleteContractConfig(params.contractconfig_id)
				    }
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.table_busy = true
				this.contractsConfig = await this.loadContractsConfig(this.horse.horse_id)
				this.table_busy = false
			},
			async checkForm() {
				if(!this.processing) {
					this.processing = true
				}
			},
			async openModalAddContractConfig() {
				this.error_message = ""
				this.ready = false
				this.season_options = []
				this.$refs.modalAddContractConfig.show()
				
				let used_season = []
				for (let index = 0; index < this.contractsConfig.length; index++) {
					const element = this.contractsConfig[index];
					used_season.push(element.contractconfig_season)
				}

				let seasons = await this.loadSeasons()
				for (let i = 0; i < seasons.length; i++) {
					if(used_season.indexOf(seasons[i].season_id) == -1 ) {
						this.season_options.push({
							id: seasons[i].season_id,
							name: seasons[i].season_label,
						})
					}
				}

				if(this.season_options.length == 0) {
					this.ready = false
					this.error_message = "monte.no_season_dispo"
				}
				else{
					this.ready = true
				}
			},

			async saveAddContractConfig() {
				if(!this.processing)
				{
					this.processing = true

					if(!this.season) {
						this.errors.push("season")
					}

					if(this.errors.length > 0)
					{
						this.processing = false
						return false
					}

					let contract = await this.addContractConfig(this.horse.horse_id, this.season.id)
					
					let params = {
						contractconfig_id: contract
					}

					this.$router.push({ name: 'ContractConfigForm', params : params })
				}
				
			},
			deleteContractConfig(contractconfig_id) {
				this.removeContractConfig(contractconfig_id)
					.then(() => {
						this.unselectAll()
						this.init_component()
					})
			},
			unselectAll() {
        		this.$refs.contract_config.unselectAll()
        	}
		},
		computed: {
			filtredContractConfig: function() {
				return this.contractsConfig
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		},
	}
</script>
